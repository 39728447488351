import React from 'react';
import Layout from '../components/layout/Layout';
import PDFViewer from '../components/PDFViewer';
import { Section, SectionTitle } from '../components/Section';
import gsaCatalog from '../pdf/VQ-Feather-LLC-GSA-Catalog.pdf';
import { Helmet } from 'react-helmet';

const VQFeatherLLCCatalog = () => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>VQ Feather GSA MAS IT Catalog</title>
    </Helmet>
    <Section>
      <SectionTitle headingLevel='h1' className='text-blue'>
        VQ Feather GSA MAS IT Catalog
      </SectionTitle>
      <PDFViewer pdf={gsaCatalog} pdfTitle='VQ Feather GSA MAS IT Catalog' />
    </Section>
  </Layout>
);

export default VQFeatherLLCCatalog;
